<template>
  <div class="healthList">
    <div class="top_box">
      <div class="img">
        <img v-if="sportData.avatar != ''" :src="sportData.avatar" alt="" />
        <img v-else src="./img/default_avatar.png" alt="" />
      </div>
      <div class="txt">
        <div class="txt_t">今日运动步数</div>
        <div class="txt_b">{{ sportData.todayStepNumber }} <span>步</span></div>
      </div>
    </div>
    <div class="center_box">
      <div class="item">
        <div class="box_l">
          昨日运动步数<span>{{ sportData.yesterdayStepNumber }}</span
          >步
        </div>
        <div class="box_r">
          <div
            :class="['getPointBtn', { zero: sportData.yesterdayPoint == 0 }]"
            @click="getPoints(-1)"
            v-if="sportData.yesterdayPointType == 0"
          >
            可领取{{ sportData.yesterdayPoint }}甜蜜值
          </div>
          <div
            class="getPointBtn ok"
            v-else-if="sportData.yesterdayPointType == 1"
          >
            领取成功{{ sportData.yesterdayPoint }}甜蜜值
          </div>
        </div>
      </div>
      <div class="item">
        <div class="box_l">
          前日运动步数<span>{{ sportData.vorGeSternStepNumber }}</span
          >步
        </div>
        <div class="box_r">
          <div
            :class="['getPointBtn', { zero: sportData.vorGeSternPoint == 0 }]"
            @click="getPoints(-2)"
            v-if="sportData.vorGeSternPointType == 0"
          >
            可领取{{ sportData.vorGeSternPoint }}甜蜜值
          </div>
          <div
            class="getPointBtn ok"
            v-else-if="sportData.vorGeSternPointType == 1"
          >
            领取成功{{ sportData.vorGeSternPoint }}甜蜜值
          </div>
        </div>
      </div>
    </div>
    <div class="messageTitleBox">
      <p><span>打卡记录</span></p>
    </div>
    <div class="list">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        ref="list"
        :immediateCheck="false"
        :finishedText="finishedText"
        :commonOperaParams="commonOperaParams"
        :beforeMyFunction="beforeMyFunction"
      >
        <div v-for="(item, index) in listData" :key="index" class="oItem">
          <div class="oItem-head">
            <!-- <div class="oItem-headName">
            <div class="oItem-avatar" v-if="item.avatar">
              <img :src="item.avatar" alt="" />
            </div>
            <div class="oItem-avatar" v-else>
              <img src="@/assets/img/default_avatar.png" alt="" />
            </div>
            <div class="oItem-tl oItem-name">
              {{ item.userName ? formatStrFunc(item.userName) : "" }}
            </div>
          </div> -->
            <div class="oItem-t">
              <span class="oItem-tr oItem-time">{{ item.insertTime }} </span>
              <span
                :class="['oItem-time', 'oItem-status', getStatusClass(item)]"
                >{{ getAuditStatus(item) }}</span
              >
            </div>
          </div>
          <div class="oItem-c" v-if="item.healthPic">
            <div
              class="item"
              v-for="(items, indexs) in item.healthPic"
              :key="indexs"
            >
              <img :src="items" alt="" />
            </div>
          </div>
          <div class="oItem-b">
            {{ item.proposalContent }}
          </div>
        </div>
      </v-list>
    </div>
    <!-- 打卡按钮 -->
    <div class="btn" @click="goDaka">去打卡</div>
  </div>
</template>

<script>
import {
  myHealthUrl,
  weChatMovementUrl,
  weChatMovementPointUrl,
} from "./api.js";
import { toRegister, gloabalCount } from "@/utils/common.js";
import { getOption, formatStr } from "@/utils/utils.js";
import { wishType } from "./map.js";
import { auditStatusMap } from "@/views/futureNeighbourhood/wishes/map";
var moment = require("moment");
export default {
  name: "healthList",
  data() {
    return {
      commonOperaParams: {
        showMy: false,
        isCommonOpera: false,
        name: "运动打卡",
        operaUrl: "health",
        myUrl: "myWishes",
        noMyOpera: false,
        createText: "去打卡",
      },
      isOne: false,
      finishedText: "没有更多了",
      finished: false,
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 20,
      },
      sportData: {},
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
  },
  created() {
    if (this.$route.query.stepList) {
      localStorage.setItem("stepLits", this.$route.query.stepList);
    }
  },
  async mounted() {
    gloabalCount("", 4, 1);
    if (await toRegister(this.$route.path, this.$route.query, "运动打卡")) {
      this.getWishList();
      this.getStep();
    }
  },
  methods: {
    getPoints(day) {
      if (day == -1 && this.sportData.yesterdayPoint == 0) {
      } else if (day == -2 && this.sportData.vorGeSternPoint == 0) {
      } else {
        let params = {
          userId: this.userId,
          tenantId: this.tenantId,
          communityId: this.communityId,
          roomId: this.roomId,
          intraday: this.getDateStr(day),
        };
        this.$axios.post(`${weChatMovementPointUrl}`, params).then((res) => {
          if (res.code === 200) {
            this.$toast(res.msg);
            this.getStep();
          }
        });
      }
    },
    getDateStr(AddDayCount) {
      let date = new Date();
      date.setDate(date.getDate() + AddDayCount);
      let year = date.getFullYear();
      let month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let str = `${year}-${month}-${day}`;
      console.log(str);
      return str;
    },
    getStep() {
      let list;
      if (localStorage.getItem("stepLits")) {
        list = localStorage.getItem("stepLits").split(",");
      } else {
        this.sportData = {
          avatar: "",
          todayStepNumber: 0,
          yesterdayStepNumber: 0,
          yesterdayPoint: 0,
          vorGeSternStepNumber: 0,
          vorGeSternPoint: 0,
        };
        return;
      }
      let params = {
        userId: this.userId,
        todayStepNumber: Number(list[2]),
        yesterdayStepNumber: Number(list[1]),
        vorGeSternStepNumber: Number(list[0]),
      };
      this.$axios
        .get(`${weChatMovementUrl}`, { params: params })
        .then((res) => {
          if (res.code === 200) {
            this.sportData = res.data;
          }
        });
    },
    beforeMyFunction() {
      if (this.isOne) {
        this.$toast("今日已打卡");
      }
    },
    goDaka() {
      if (this.isOne == true) {
        this.$toast("今日已打卡");
      } else {
        this.$router.push({
          name: "health",
        });
      }
    },
    getStatusClass(item) {
      if (item.auditStatus == 1) {
        return "out";
        // return getOption(item.auditStatus, auditStatusMap, "key").class;
      } else if (item.auditStatus == 0) {
        return "reback";
      } else {
        return "";
      }
    },
    formatStrFunc(str) {
      if (str) {
        return formatStr(str);
      }
    },
    getAuditStatus(item) {
      if (item.auditStatus || item.auditStatus == 0) {
        return getOption(item.auditStatus, auditStatusMap, "key").value;
      } else {
        return "";
      }
    },
    getStatus(item) {
      if (item.wishType) {
        return getOption(item.wishType, wishType, "key").value;
      } else {
        return "";
      }
    },
    onLoad() {
      this.getWishList();
    },
    getWishList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        userId: this.userId,
      };
      this.$axios.get(`${myHealthUrl}`, { params }).then((res) => {
        if (res.code === 200) {
          if (res.data.hasTodayRecord) {
            this.isOne = true;
            this.commonOperaParams.noMyOpera = true;
          }
          if (res.data.pages <= res.data.current) {
            this.finished = true;
          } else {
            this.$refs.list._data.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            return;
          }
          res.data.records.forEach((item) => {
            if (item.healthPic) {
              item.healthPic = item.healthPic.split(",");
              item.healthPic.forEach((items, index) => {
                items = this.$handleImg(140, 140, items);
              });
            }
            this.listData.push(item);
          });
          this.$forceUpdate();
        } else {
        }
      });
    },
    toDetail(item) {
      this.$router.push({
        name: "healthDetail",
        query: { id: item.id },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.healthList {
  min-height: 100vh;
  background: #fafafa;
  .btn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #ffc055 0%, #fe9514 100%);
    border-radius: 10px;
    text-align: center;
    line-height: 66px;
    font-weight: 600;
    color: #ffffff;
    position: fixed;
    bottom: 42px;
    left: 50%;
    transform: translateX(-50%);
  }
  .messageTitleBox {
    p {
      font-size: 32px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      padding: 0 48px;
      margin-bottom: 58px;
      span {
        position: relative;
        z-index: 1;
        &::before {
          content: "";
          width: 130%;
          height: 16px;
          background: #e2e2e2;
          border-radius: 7px;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          z-index: -1;
        }
      }
    }
  }
  .center_box {
    border-top: 2px solid rgba(0, 0, 0, 0.05);
    border-bottom: 2px solid rgba(0, 0, 0, 0.05);
    padding: 20px 24px 20px 36px;
    margin-bottom: 28px;
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      .box_l {
        font-size: 24px;
        color: rgba(0, 0, 0, 0.5);
        span {
          font-size: 44px;
          color: rgba(0, 0, 0, 0.85);
          margin-left: 38px;
          margin-right: 18px;
        }
      }
      .box_r {
        .getPointBtn {
          // width: 174px;
          text-align: center;
          box-sizing: border-box;
          line-height: 44px;
          height: 44px;
          background: linear-gradient(180deg, #ffc055 0%, #fe9514 100%);
          border-radius: 10px;
          font-size: 24px;
          color: #ffffff;
          padding: 0 12px;
          box-sizing: border-box;
        }
        .ok {
          border: 2px solid #fe9514;
          background: transparent;
          color: #fe9514;
        }
        .zero {
          border: none;
          background: #ccc;
        }
      }
    }
  }
  .top_box {
    width: 100%;
    height: 226px;
    display: flex;
    align-items: center;
    .img {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      overflow: hidden;
      margin-left: 38px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .txt {
      margin-left: 28px;
      .txt_t {
        font-size: 24px;
        color: rgba(0, 0, 0, 0.5);
        margin-bottom: 12px;
      }
      .txt_b {
        font-size: 44px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        span {
          font-weight: 400;
          font-size: 26px;
        }
      }
    }
  }
  .list {
    padding: 0 24px;
    box-sizing: border-box;

    .oItem {
      border-radius: 16px;
      box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
      background: #fff;
      padding: 26px 28px 32px 36px;
      border-bottom: 1px solid #f7f7f7;
      margin-bottom: 16px;
      box-sizing: border-box;
      .oItem-head {
        //   display: flex;
        //   justify-content: space-between;
        font-weight: bold;
        margin-bottom: 12px;
        align-items: center;
      }
      .oItem-headName {
        display: flex;
        align-items: center;
      }

      .oItem-t {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .oItem-avatar {
        width: 80px;
        height: 80px;
        /*border-radius:30px;*/
        /*border: 0.5px solid #999;*/
      }
      .oItem-avatar img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        /*border: 0.5px solid #999;*/
      }
      .oItem-name {
        margin-left: 15px;
        font-size: 32px;
        font-weight: bold;
      }
      .oItem-time {
        font-size: 24px !important;
        font-weight: normal !important;
        color: rgba(0, 0, 0, 0.25);
      }
      .oItem-status {
        color: rgba(0, 0, 0, 0.25);
      }
      .out {
        color: #367bf4;
      }
      .reback {
        color: #ff9818;
      }
      .oItem-c {
        display: flex;
        .item {
          width: 140px;
          height: 140px;
          margin-right: 20px;
          img {
            width: 100%;
            height: 100%;
          }
          border-radius: 10px;
        }
      }
      .oItem-b {
        font-size: 32px;
        width: calc(100% - 60px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 30px;
        span {
          color: salmon;
        }
      }
    }
  }
}
</style>
